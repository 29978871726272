<template>
  <HeaderHome/>
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="loading"/>

      <div class="row body-box">
        <div class="col-md-12" ref="lote">
          <h1>Pedido realizado com sucesso!</h1>
          <h2 v-if="boleto_link">
            <a :href="boleto_link" target="_blank">Clique Aqui para Abrir o LINK do BOLETO</a>
          </h2>
          <p>
            <b>Parabéns! Sua inscrição foi efetivada com sucesso, e está sendo processada pela PAGARME. </b> <br/>
            <br/>Para facilitar a consulta,
            enviamos um e-mail para cada kit adquirido com as informações cadastrais do usuário,
            dados da realização do evento e informações sobre o kit.
          </p>
          <p>
            Não esqueça de conferir na página de evento como será feita a retirada!
          </p>
        </div>
        <div class="row mt-4">
          <div class="col-md-6  mt-4 text-right">
          <button class="btn btn-primary" @click.prevent="goto_home">Voltar a Home</button>
        </div>
          <div class="col-md-6  mt-4">
            <button class="btn btn-success" @click.prevent="goto_conta">Área do Atleta</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import {defineComponent} from "vue";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Loader from "../components/Loader";
import Orders from "../api/Orders";
import Carrinho from "../api/Carrinho";
import Auth from "../api/Auth";

export default defineComponent({
  name: "SucessoBoletoPage",
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Loader
  },
  computed: {},
  data() {
    return {
      loading: true,
      "orders": [],
      "orders_infos": [],
      "price_order": 0,
      "boleto_link":null,
    };
  },
  methods: {
    select_forma(payment_method) {
      this.payment_method = payment_method.type;
    },
    goto_home() {
      window.scrollTo(0, 0);
      this.$router.push('/');
    },
    goto_conta() {
      window.scrollTo(0, 0);
      this.$router.push('/minha-conta/');
    },
  },
  mounted() {
    this.orders = Carrinho.get_itens();
    this.price_order = 0;
    setTimeout(() =>{
     let Boleto =  window.localStorage.getItem('BOLETO-LINK');
     if(Boleto != ""){
       this.boleto_link = Boleto;
     }

      window.localStorage.removeItem('BOLETO-LINK')
    },800);

    Orders.get_itens(this.orders, Auth.token()).then((request) => {
      if (request.status == 200) {
        this.orders_infos = request.data.orders;
        this.price_order = request.data.price;

        this.loading = false;
      }
    });

  },
  unmounted() {
  }
});
</script>

<style scoped>
.img-card img {
  max-width: 100%;
  max-height: 100%;
}

.mt-4 {
  margin-top: 30px;
}

.mt-2 {
  margin-top: 15px;
}

.pr-4 {
  padding-right: 30px;
}

.card {
  border: 1px solid #dadada;
}

.mb-4 {
  margin-bottom: 30px;
}

.mb-2 {
  margin-bottom: 15px;
}

.price {
  padding-bottom: 6%;
  padding-top: 7%;

  padding-left: 0;

}

.valor span {
  color: #0f0f0f;
}

b {
  color: #eea72b;
}

.price span {
  color: #d31f28;
  cursor: pointer;
}

.inscricoes.selected .card {
  background-color: #d31f28;
  color: #fff;
  transition: background-color 1s ease;
}

.inscricoes .card p {
  font-size: 20px;
}

.inscricoes .card {
  cursor: pointer;
}

</style>
